import logo from "./logo.svg";
import "./App.css";
import { Col, Container, Row, Image, Button, Table } from "react-bootstrap";

import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import mainImg from "./main.png";

import axios from "axios";
import { useEffect, useState } from "react";
import { socket } from "./socket";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PUMP_FUN_LINK, TELEGRAM_LINK, TWITTER_LINK } from "./links";

function App() {
  const [fetchKey, updateFetchKey] = useState(0);
  const [leaderboard, setLeaderboard] = useState([]);
  const [liveCount, setLiveCount] = useState(0);

  useEffect(() => {
    function onConnect(args) {
      setLiveCount(args);
      console.log("CONNECTED");
    }

    function onDisconnect() {}

    function onFooEvent(value) {
      toast.success(`NEW HIGH SCORE SET ${value.highscore}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      updateFetchKey((prev) => prev + 1);
    }

    socket.on("connected", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("newHighscore", onFooEvent);

    return () => {
      socket.off("connected", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("newHighscore", onFooEvent);
    };
  }, []);

  useEffect(() => {
    (async () => {
      let resp = await axios.get("https://api.rugstrike.fun/top");
      if (Array.isArray(resp.data.docs)) {
        setLeaderboard(resp.data.docs);
      }
    })();
  }, [fetchKey]);

  return (
    <>
      {" "}
      <ToastContainer />
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#home">Rug Strike</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href={PUMP_FUN_LINK} target="_blank">
                PumpFun
              </Nav.Link>
              <Nav.Link href={TWITTER_LINK} target="_blank">
                X
              </Nav.Link>
              <Nav.Link href={TELEGRAM_LINK} target="_blank">
                Telegram
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="hero">
        <Container>
          <Row fluid>
            <Col sm={12} md={6}>
              <h1>Rug Strike</h1>
              <h3>Solana Play to Earn Game</h3>
              <p>
                Kill infamous rug lords and prove your self against others to
                earn $RS. Each time a player beats the previous high score, they
                will be airdropped an amount of $RS, with the transaction
                visible on this page. $RS is a token on Solana that can be sold
                on the market, and will have future utilities in this game, as
                well as others we plan to release!
              </p>
              {liveCount ? (
                <p className="animate__animated animate__pulse animate__infinite">
                  <b>{liveCount} Players Ingame</b>
                </p>
              ) : null}
              <Button
                variant="primary"
                size="lg"
                onClick={() => {
                  window.open("https://rugstrike.fun", "_blank");
                }}
              >
                Play Game
              </Button>{" "}
              <Button
                variant="success"
                size="lg"
                onClick={() => {
                  window.open(PUMP_FUN_LINK, "_blank");
                }}
              >
                Buy $RS
              </Button>{" "}
            </Col>
            <Col>
              <Image rounded="tru" src={mainImg} fluid />
            </Col>
          </Row>

          <div className="leader">
            <h2>Leaderboard</h2>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Wallet</th>
                  <th>Score</th>
                  <th>Airdropped?</th>
                </tr>
              </thead>
              <tbody>
                {leaderboard.map((k, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{k.wallet}</td>
                    <td>{k.highscore}</td>
                    <td>
                      {k.signature ? (
                        <a
                          href={`https://solscan.io/tx/${k.signature}`}
                          target="_blank"
                        >
                          {k?.amount} $RS
                        </a>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Container>
      </div>
    </>
  );
}

export default App;
